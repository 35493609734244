import * as React from 'react';
import '@fontsource/comfortaa/300.css'; // Weight 300.
import '@fontsource/comfortaa'; // Defaults to weight 400.
import '@fontsource/comfortaa/500.css'; // Weight 500.
import '@fontsource/comfortaa/600.css'; // Weight 600.
import '@fontsource/comfortaa/700.css'; // Weight 700.
import Layout from '../components/layout';
import styled from 'styled-components';
import Container from '@material-ui/core/Container';
import * as styles from '../styles/contact.module.css';
import { useForm, ValidationError } from '@formspree/react';

const ContactStyled = styled.div`
	font-size: 20px;

	.background {
		height: 63vh;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;
// styles
const pageStyles = {
	color: '#232129',
	webkitFontSmoothing: 'antialiased',
	fontFamily: 'Comfortaa',
	marginTop: '3em',
};
const headingStyles = {
	marginTop: 0,
	marginBottom: 64,
	maxWidth: 320,
};
// markup

export default function BusinessPage() {
	return (
		<ContactStyled>
			<Layout>
				<Container>
					<div style={{ margin: '6em 2em' }} class='background'>
						<center>
							<h2>Thank you for choosing Grow My Life</h2>
							<p>
								We will be back in touch via email with the next steps. Please
								check your junk email or spam folder if you haven’t received any
								email from us.
							</p>
						</center>
					</div>
				</Container>
			</Layout>
		</ContactStyled>
	);
}
